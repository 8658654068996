<template>

  <div v-if="userIsXtramileAdmin">

    <v-dialog v-model="dialog">
      <v-card flat>
        <v-menu
        v-model="menu_form"
        min-width="400px"
        :eager="true"
        :position-x="menu_form_pos_x-200"
        :position-y="menu_form_pos_y+10"
        :close-on-content-click="false"
        :close-on-click="false"
        :style="{zIndex:9999}"
        >
        <!-- :close-on-click="false" -->
        <v-card class="pa-6"
        min-width="400px"
        >
          <!-- <v-form v-model="form"> -->
          <v-toolbar dense flat>
            <v-toolbar-title><small>Evaluation: {{values.field}}</small></v-toolbar-title>
            <v-spacer />
            <v-btn icon @click="closeDialog"><v-icon>mdi-close</v-icon></v-btn>
          </v-toolbar>
          <v-divider />

          <div class="pa-2 text-wrap" style="max-width: 400px;">
            <div>
              <del class="red lighten-3 muted">{{values.value}}</del>
            </div>

            <div>
              <ins class="green lighten-3">{{values.updated_value}}</ins>
            </div>
          </div>

          <v-divider class="mb-4" />
          <div class="mb-4">
            <v-textarea
            outlined
            dense
            label="value"
            v-model="values.value"
            :rules="[$rules.required]"
            hide-details
            />
          </div>

          <div class="mb-2">
            <v-textarea
            outlined
            dense
            ref="updated_value"
            label="updated_value"
            v-model="values.updated_value"
            :rules="[$rules.required]"
            hide-details
            />
          </div>
          <div class="mb-4">
            <v-autocomplete
              label="dictionnary"
              v-model="dictionnary"
              :items="dictionnaryItems"
              @change="handleDictionnary"
              dense
              outlined
            />
          </div>
          <v-toolbar dense flat transparent class="pl-0">

            <v-spacer />
            <v-btn depressed rounded small color="success" @click="createEvaluation"><v-icon class="mr-1" size="20">mdi-plus</v-icon>Add</v-btn>
          </v-toolbar>
          <div class="text-right">
            <!-- :rules="[$rules.required]" -->
          </div>

          <!-- </v-form> -->
        </v-card>
      </v-menu>
        <v-toolbar dense flat transparent>
          <v-toolbar-title>
            Evaluation: {{loaded_values.title}}
          </v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-divider />

        <!-- <v-row>
          <v-col cols="3" class="scroll height-100">
            <v-tabs vertical v-model="tab">
              <v-tab v-for="(field, i) in fields" :key="'tb'+i">{{field}}</v-tab>
            </v-tabs>
          </v-col>
          <v-col cols="3">

          </v-col>
          <v-col cols="6">

          </v-col>
        </v-row> -->


        <v-divider />
        <div class="height-70vh yellow lighten-3 scroll">
          <div v-for="(field, i) in fields" :key="'azd'+i" class="ma-0 height-max-100">
            <div :class="[field.data ? 'pa-4' : 'pr-4 pl-4']">
              <div><small class="grey--text">{{field.key}} ({{(evaluationByFields[field.key] || []).length}})</small></div>
              <v-divider div class="mb-4" />
              <div class="mb-4">
                <v-chip v-for="(evaluation, j) in evaluationByFields[field.key]" :key="'cjh'+j" class="mb-1 mr-2" color="white" large>
                  <div class="pa-1">{{j+1}}. {{evaluation.value}}<small class="d-block">{{evaluation.user_name}}</small></div>
                  <v-icon size="18" class="mr-2 ml-2" @click="removeEvaluation(evaluation.id)">mdi-delete</v-icon>
                </v-chip>
              </div>
              <div class="" @click="(e) => handleFieldClick(e, field.key)" v-html="stylizeEvaluation(field.data, evaluationByFields[field.key])">
              </div>
            </div>


          </div>
        </div>
      </v-card>
    </v-dialog>

    <div>
      <a @click="dialog = true">Job evaluation</a>
    </div>

  </div>



</template>


<script>
import { mapState } from "vuex";

import eval_dictionnary from "./eval_dictionnary"

export default {
  name: 'FormJobCompactEvaluation',
  data: () => ({
    tab: 0,
    form: false,
    menu_form: false,
    menu_form_pos_x: 10,
    menu_form_pos_y: 10,

    default_values: {
      job_id: undefined,
      field: undefined,
      value: undefined,
      updated_value: undefined,
    },
    values: {
      job_id: undefined,
      field: undefined,
      value: undefined,
      updated_value: undefined,
    },
    dictionnary: '',
    dictionnaryItems: eval_dictionnary,

    dialog: false,
    loading: false,
    evaluationItems: [],
    evaluationByFields: {},
    fields: [
    // ['id', []],
    // ['ref', []],
    // ['title', []],
    // ['description', []],
    // ['profile', []],
    // ['url', []],
    // ['image_url', []],
    // ['images', []],
    //
    // ['xtramile_sector', ['sector.ref.value', 'sector.ref.id']],
    // ['xtramile_sector_id', []],
    //
    // ['location_country', []],
    // ['location_area', []],
    // ['location_country_code', []],
    // ['location_region', []],
    // ['location_region_code', []],
    // ['location_department', []],
    // ['location_department_code', []],
    // ['location_city', []],
    // ['location_city_code', []],
    // ['location_geoloc', []],
    // ['location', []],
    //
    //
    // ['quantity', []],
    // ['date', []],
    // ['start_date', []],
    // ['end_date', []],
    // ['age', []],
    // ['age_min', []],
    // ['age_max', []],
    // ['contract', []],
    // ['contract_type', []],
    // ['contract_schedule', []],
    // ['contract_remote', []],
    // ['contract_status', []],
    // ['contract_start_date', []],
    // ['contract_end_date', []],
    // ['duration', []],
    // ['duration_min', []],
    // ['duration_max', []],
    // ['duration_min_period', []],
    // ['duration_max_period', []],
    // ['salary', []],
    // ['salary_min', []],
    // ['salary_max', []],
    // ['salary_period', []],
    // ['salary_currency', []],
    // ['education', []],
    // ['education_level', []],
    // ['education_languages', []],
    // ['education_domains', []],
    // ['experience', []],
    // ['experience_level', []],
    // ['experience_domains', []],
    // ['experience_min', []],
    // ['experience_max', []],
    // ['experience_max_period', []],
    // ['experience_min_period', []],
    // ['company', []],
    // ['company_title', []],
    // ['company_description', []],
    // ['company_image_url', []],
    // ['company_url', []],
    // ['contact', []],
    // ['contact_name', []],
    // ['contact_position', []],
    // ['contact_phone', []],
    // ['contact_email', []],
    // ['diplomas', []],
    // ['skills', []],
    // ['advantages', []],
    // ['custom', []],
    // ['tags', []],
  ]

  }),
  props: {
    loaded_values: {
      type: [Object],
      default: () => ({})
    }
  },
  watch: {
    dialog (n) {
      if (n) this.loadEvaluations()
    },
    loaded_values () {
      // this.loadEvaluations()
    }
  },
  computed: {
    ...mapState("user", {
      userIsXtramileAdmin: (state) =>
        state.user && state.user.role && state.user.role.title[0] === "x",
    }),

  },
  methods: {

    handleDictionnary (v) {
      this.values.updated_value = (this.values.updated_value || '') + (v || '')
      this.$nextTick(() => {
        this.dictionnary = ''
      })
    },

    stylizeEvaluation (text, evaluations = []) {
      let j = 0
      evaluations.forEach((evaluation, i) => {
        const regDel = new RegExp(evaluation.value, 'gm')
        text = text.replace(regDel, () => '<del class="red lighten-3 muted">'+ (++j) + '. ' + evaluation.value + '</del>' + '<ins class="green lighten-3">' + evaluation.updated_value + '</ins>')
      })
      return text
    },

    closeDialog () {
      this.menu_form = false
      this.values =  {...this.default_values}
    },

    handleFieldClick (e, field) {
      // console.log('--')
      // console.log(e)
      //
      // console.log(field, this.loaded_values.parsing_result)
      // console.log(this.$nestedData(field, this.loaded_values.parsing_result))

      const selection = window.getSelection()
      // console.log(selection)
      const selected_text = selection.toString() || selection.baseNode.data.slice(selection.anchorOffset, selection.extentOffset) || this.$nestedData(field, this.loaded_values.parsing_result)


      // console.log(selected_text)

      const values = {...this.default_values}
      values.value = selected_text
      values.field = field
      values.job_id = this.loaded_values.id
      this.values = values

      this.menu_form = true
      this.menu_form_pos_x = e.clientX
      this.menu_form_pos_y = e.clientY
      this.$refs.updated_value.focus()

    },

    loadEvaluations () {

      const handleDeepValue = (value) => {
        if (value) {
          // example
          const results = []
          const recurse = (data, prevKeys = []) => {
            if (typeof data !== 'undefined') {
              if (data instanceof Array) {
                for (let i = 0, len = data.length; i < len; i++) {
                  recurse(data[i], [...prevKeys].concat([i]))
                }
              } else if (data instanceof Object) {
                for (const key of Object.keys(data)) {
                  recurse(data[key], [...prevKeys].concat([key]))
                }
              } else if (data || data === 0) {
                results.push({data, key: prevKeys.join('.')})
              }
            }
          }
          recurse(value)
          return results
        }
      }
      this.fields = handleDeepValue(this.loaded_values.parsing_result)
      this.loading = true
      return this.$services.api_programmatic.job_evaluation
        .search({where:{job_id:this.loaded_values.id}})
        .then(({data}) => {
          const evaluationByFields = {}
          if (data.length > 0) {
            this.evaluationItems = data
            this.fields.forEach((field, i) => {
              data.forEach((datum, j) => {
                if (datum.field === field.key) {
                  if (!evaluationByFields[field.key]) {
                    evaluationByFields[field.key] = []
                  }
                  evaluationByFields[field.key].push(datum)
                }
              })
            })
            this.evaluationByFields = evaluationByFields
          } else {
            this.evaluationByFields = {}
            this.evaluationItems = []
          }
        })
        .catch(e => {
          this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
        })
        .finally(() => {
          this.loading = false
        })
    },

    createEvaluation () {
      this.loading = true
      return this.$services.api_programmatic.job_evaluation
        .create({values:this.values})
        .catch(e => {
          this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
        })
        .finally(() => {
          this.menu_form = false
          this.loading = false
          this.loadEvaluations()
        })

    },

    removeEvaluation (id) {
      this.loading = true
      return this.$services.api_programmatic.job_evaluation
        .remove({where:{id}})
        .catch(e => {
          this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
        })
        .finally(() => {
          this.loading = false
          this.loadEvaluations()
        })

    },

    // updateEvaluation (values) {
    //   values.loaded_values.id = this.loaded_values.id
    //   this.loading = true
    //   return this.$services.api_programmatic.job_evaluation
    //     .update({where:{id:}, values:this.values})
    //     .finally(() => {
    //       this.loading = false
    //     })
    // }

  }
}


</script>
